<template>
  <div class="px-4 pt-4 fill-height wrap-rules">

    <div v-if="loading" class="fill-height d-flex align-center justify-center">
      <div style="width: 100px">
        <v-progress-linear rounded indeterminate :color="wsACCENT" />
      </div>
    </div>

    <!-- Message Content -->
    <div v-else
         class="d-flex mb-2"
         v-for="(item,i) in itemsFiltered" :key="i"
         :class="[{'justify-end' : item.is_user}]"
    >
      <div
          v-if="!item.is_user"
          class="fill-height mr-2"
          style="width: 24px"
      >

        <div  style="" v-if="displayAuthorCondition(item,i)">
          <img v-if="item.img" height="24px" width="24px"  :src="item.img" style="border-radius: 50%" />
          <v-sheet v-else height="24" width="24" :color="wsBACKGROUND" class="d-flex align-center justify-center" style="border-radius: 50%">
            <v-icon v-if="item.is_system" size="18" :color="wsDARKLIGHT" >mdi-robot</v-icon>

            <v-icon v-else size="18" >mdi-account-tie</v-icon>

          </v-sheet>
        </div>


      </div>

      <v-sheet
          style="border-radius: 8px; padding : 10px"
          :color="item.is_user ? wsBACKGROUND : '#ffffff'"
          class="wrap-rules"
          max-width="248"
      >
        <h5 v-if="displayAuthorCondition(item,i) && !item.is_system"
            class="wsDARKER mb-2"
            :class="[{'text-right' : item.is_user}]"
        >
          {{ !item.is_user ? item.author : $store.state.auth.user.firstname }}
        </h5>

        <v-sheet v-if="getImageUrl(item.text)"
                  @click="openImage(getImageUrl(item.text))"
                  :color="wsBACKGROUND" style="position: relative" class="pointer" height="150px" width="100%">
          <v-img height="150px" width="220px"  :src="getImageUrl(item.text)" />
        </v-sheet>
        <h5  v-else-if="getFileUrl(item.text)"
             @click="downloadFile(item.text)"
             class="font-weight-regular pointer wsDARKER">
          {{ getFileName(item.text) }}
          <v-icon :color="wsDARKER">mdi-paperclip</v-icon>
        </h5>
        <h5 v-else class="font-weight-regular wsDARKER wrap-rules" v-html="prepareText(item.text)"></h5>


        <h5 style="font-size: 12px" class="font-weight-regular wsDARKLIGHT text-right mt-2">{{ MONTH_DAY_TIME(item.date , true , true )  }}</h5>
      </v-sheet>



    </div>

    <!-- Placeholder -->
    <div style="height: 50px"></div>


    <!-- Message Input Footer-->
    <portal v-if="entity.status !== 3 && !loading" to="support_footer">
      <ws-file-uploader
          v-if="displayUpload"
          :style="`background-color: ${wsBACKGROUND}`"
          style=" border-radius : 0; border-bottom-right-radius: 16px;border-bottom-left-radius : 16px; !important; border: none !important; "

          @success="handleUploadFile"
          public
          is-public
          class="overflow-hidden"
          height="124px"
      >
      </ws-file-uploader>

      <v-sheet
          max-height="200"
          width="100%"
          class=" pr-2 d-flex flex-row overflow-y-auto wsRoundedHalfBottom"
          :class="[{'pa-2' : !this.displayUpload}]"
      >

        <ws-text-field
            v-if="!displayUpload"
            class="flex-grow-1 pr-8"
            :placeholder="$t('EnterMessage')"
            v-model="entityData.text"
            rows="1"
            flat
            solo
            area
        />

        <v-btn
            v-if="!entityData.text && displayUpload"
            @click="displayUpload = false"
            :class="[{fileBackButton : !SM} , {fileBackButtonSm : SM} ]"
            icon
        >
          <v-icon :color="wsACCENT">mdi-arrow-left</v-icon>
        </v-btn>

        <v-fade-transition mode="out-in">

          <v-btn
              v-if="!entityData.text && !displayUpload"
              @click="displayUpload = true"
              key="upload"
              :class="[{sendButton : !SM} , {sendButtonSm : SM} ]"
              icon
          >
            <v-icon :color="wsDARKLIGHT">mdi-paperclip</v-icon>
          </v-btn>

          <v-btn v-else-if="!displayUpload"
                 @click="sendMessage"
                 key="send"
                 :class="[{sendButton : !SM} , {sendButtonSm : SM} ]"
                 :loading="LOADING"
                 :disabled="LOADING || !entityData.text"
                 icon
          >
            <v-icon :color="wsATTENTION">mdi-send</v-icon>
          </v-btn>

        </v-fade-transition>


      </v-sheet>
    </portal>

    <!-- Image Viewer-->
    <v-sheet
        v-if="displayImageDialog"
        :color="wsBACKGROUND"
        style="position:fixed; top : 0 ; bottom : 0 ; left : 0; right : 0; z-index : 1"
    >
      <v-sheet
          @click="displayImageDialog = false"
          :color="wsATTENTION"
          class="d-flex align-center justify-center pointer"
          style="border-radius : 50px; position: fixed; top : 12px; right : 12px; z-index : 22"
          height="32" width="32"
          v-ripple dark
      >
        <v-icon>mdi-close</v-icon>
      </v-sheet>
      <v-img :src="selectedImage" position="50% 50%" contain height="100%" width="100%" />
    </v-sheet>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "chatSupportCreate",
  props : {
    chatId : {
      type : String
    },
    newChatType : {
      type : String
    }
  },
  data() {
    return {
      entityData : {},
      items : [],
      loading : false,
      displayImageDialog : false,
      selectedImage : null,
      displayUpload : false,
      entity : {}
    }
  },
  computed : {
    ...mapState('support' , ['chatMessagesArray' , 'chatNotificationsArray']),
    newMessagesCount() {
      return this.chatMessagesArray.length
    },
    itemsFiltered() {
      let type = this.entity.type || this.newChatType
      let items = [
        { is_system : true ,
          text : this.$t(`support.chat.auto_messages.${type}`)
        }
      ]

      return [ ...items, ...this.items ]
    }
  },
  methods : {
    ...mapActions('support', [
      'ADD_BUSINESS_REQUEST',
      'GET_BUSINESS_REQUEST',
      'ADD_COMMENT',
    ]),

    prepareText(text) {
      const urlRegex = /<*((https?:\/\/)[^\s]+)>*/g;

      return text.replace(urlRegex, (match, url) => {
        // Remove any leading '<' and trailing '>' from the URL if present
        const cleanUrl = url.replace(/^<|>$/g, '');
        try {
          // Create a URL object to ensure it's a valid URL
          const urlObj = new URL(cleanUrl);
          // Construct the 'domain' to include everything after the protocol but without www.
          // Start with the hostname without www.
          let domain = urlObj.hostname.replace(/^www\./, '');
          // Append pathname and search to the domain
          domain += urlObj.pathname + urlObj.search;

          // Shorten the text if it's longer than 30 characters
          if (domain.length > 28) {
            domain = domain.slice(0,28) + '...';
          }

          return `<a style="color : ${this.wsATTENTION}; text-decoration: none;font-weight: 400" href="${cleanUrl}" target="_blank"><b>${domain}</b></a>`;
        } catch (error) {
          console.error("Error parsing URL:", error);
          // Fallback to using the cleaned URL if parsing fails
          return `<a style="color : ${this.wsATTENTION}; text-decoration: none;font-weight: 400" href="${cleanUrl}" target="_blank"><b>${cleanUrl}</b></a>`;
        }
      });
    },
    downloadFile(text) {
      if ( this.getFileUrl(text)) {
        var a = document.createElement('a');
        a.href = this.getFileUrl(text);
        a.download = this.getFileName(text);
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    },
    async handleUploadFile(file) {
      if ( file.mime.includes('image')) {
        this.entityData.text = `#img:${file.url}`
      } else {
        this.entityData.text = `#file:${file.url} |#name:${file.name}`
      }

      await this.sendMessage()
      this.displayUpload = false
    },
    openImage(url) {
      this.selectedImage = url
      this.displayImageDialog = true
    },
    getImageUrl(text) {
      if (text.startsWith("#img:")) {
        let url = text.substring(5);
        return url
      }
      return false
    },
    getFileUrl(text) {
      if (text.startsWith("#file:")) {
        text = text.substring(6);
        let url = text.split(' |#name:')[0]
        return url
      }
      return false
    },
    getFileName(text) {
      if (text.startsWith("#file:")) {
        text = text.substring(6);
        let name = text.split(' |#name:')[1]
        return name
      }
      return false
    },

    displayAuthorCondition(item,index) {
      if ( index  <= 1 ) {
        return true
      }
      if ( this.itemsFiltered[index - 1].author_id === item.author_id ) {
        return false
      }
      return true
    },

    async addNewRequest() {
      this.entityData.type = this.newChatType;
      let result = await this.ADD_BUSINESS_REQUEST(this.entityData)
      if ( !result ) {
        this.ERROR(result)
        return
      }
      this.chatId = result.uuid
      this.$emit('add-request' , result)
      this.entityData = {}
      this.initChat(false)

    },
    async sendMessage() {

      if ( this.entityData.text.length > 4000 ) {
        return this.notify(this.$t('support.chat.message_length_warning') , 'warning')
      }

      if ( !this.chatId) {
        await this.addNewRequest()
        return
      }
      this.entityData.is_user = true
      this.entityData.uuid = this.chatId

      let result = await this.ADD_COMMENT(this.entityData)
      if ( !result ) {
        return this.ERROR()
      }
      this.items.push(result)

      this.$emit('update-request' , {issue : this.chatId , preview : this.getPreviewText(this.entityData.text) })
      this.entityData = {}
      this.scrollDown()

    },

    getPreviewText(text) {
      if ( this.getImageUrl(text) ) {
        return this.$t('Image')
      }
      if ( this.getFileUrl(text)  ) {
        return this.$t('File')
      }
      return text
    },

    async initChat(displayLoading = true) {
      if ( !this.chatId ) {
        return
      }
      if ( displayLoading)  {
        this.loading = true
      }
      let result = await this.GET_BUSINESS_REQUEST(this.chatId)
      this.loading = false
      if ( !result ) {
        return this.ERROR()
      }
      this.items = result.messages
      this.entity = result.entity
      this.$store.state.support.chatMessagesArray = this.chatMessagesArray.filter(el => el.issue !== this.chatId )
    },
    scrollDown() {
      setTimeout(() => {
        let view = document.getElementById('support_chat_content_view')
        console.log(view)
        view.scrollTop = view.scrollHeight;
      },300)

    }
  },
  watch : {
    newMessagesCount() {
      let currentChatMessages = this.chatMessagesArray.filter(el => el.issue === this.chatId && el.text )
      currentChatMessages.forEach(el => {
        this.items.push(el)
        this.scrollDown()
      })
      this.$store.state.support.chatMessagesArray = this.chatMessagesArray.filter(el => el.issue !== this.chatId )
      this.$store.state.support.chatNotificationsArray = this.chatNotificationsArray.filter(el => el.uuid !== this.chatId )
    }
  },
  async mounted() {
    await this.initChat()
    this.scrollDown()
  }
}
</script>

<style scoped>
.wrap-rules {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.sendButton {
  position: fixed;
  bottom: 100px;
  right: 38px
}
.sendButtonSm {
  position: fixed;
  bottom: 10px;
  right: 38px
}
.fileBackButton {
  position: fixed;
  bottom: 172px;
  right: 300px
}
.fileBackButtonSm {
  position: fixed;
  bottom: 82px;
  left: 10px
}
</style>